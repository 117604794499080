import { Button } from "@mui/material"
import { useAuth } from "../provider/auth"

const ReturnToModuleList = (text = null) => {
    const { auth } = useAuth()
 
    return (
        <p className={`lead mt-3 text-black text-lg`}>
            Para regresar a la lista de actividades:{" "}
            <a
                style={{
                    textDecoration: "underline",
                    marginLeft: "5px",
                }}
                href={`https://tipconsola.inatrans.cl/?config=modulelist&course_id=${auth.cookies.course_id}&course_name=${auth.cookies.course_name}&user_id=${auth.cookies.user_id}&user_name=Agustin%20Quiroz&url_cmid=${auth.cookies.url_cmid}&user_email=${auth.cookies.user_email}&url_name=modules`}
            >
                <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                    aquí
                </Button>
            </a>
        </p>
    )
}

export default ReturnToModuleList