import React, { useEffect } from "react";
import { useModal } from "../../provider/modal";
import { useApp } from "../../provider/app";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { useConfig } from "../../provider/config";
import { Box } from "@mui/system";
import { Button, Paper } from "@mui/material";
import { Load } from "../loader/Loading";
import { useAppIII } from "../../provider/app/AppIIIProvider";

const Quest = ({ player, handleStart, isModuleIII=false }) => {
  const { app } = useApp();
  const {appIII} = useAppIII()
  const [loading, setLoading] = React.useState(false);
  const { modal_state, modal_dispatch } = useModal();
  const { answers, modal_type, modal_title, correct_answer } = modal_state;
  const { configActions, set_user_response, user_response } = useConfig();
  const { onUserResponse } = configActions;
  const title = isModuleIII ? appIII.v_desc.title : app.v_desc.title;
  const isExample = title === "Actividad de ensayo" ? true : false;
  const isMobile = window.innerWidth < 900;

  useEffect(() => {
    console.log(app.config)
    console.log(answers)
    console.log(modal_state)
  }, [modal_state])
  

  const handleChange = (e) => {
    set_user_response(e.target.value);
  };

  const handleResponse = () => {
    setLoading(true);
    const type = isExample ? "intro" : "box";
    const questions = isModuleIII ? appIII.questions : app.questions;
    const idx = questions.findIndex((q) => q.title === modal_title);
    const isLastQuestion = idx === questions.length - 1;

    onUserResponse(user_response, modal_title, correct_answer, type, isModuleIII);

    setTimeout(() => {
      setLoading(false);
      if (isLastQuestion) {
        player.play();
      }
    }, isLastQuestion ? 100 : 1000);
  };

  const handleIntro = () => {
    modal_dispatch({ type: "none" });
    console.log('dispatch handlestart')
    return handleStart();
  };

  const ModalBody = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minWidth: 350,
            maxWidth: 950,
            backgroundColor: "#212529",
          },
          margin: "0 auto",
        }}
      >
        {modal_type === "intro" ? (
          <Paper elevation={3} style={{ padding: "1rem 2rem" }}>
            {app.config === "quest" && !isModuleIII && <QuestIntro />}
            {app.config === "click" && !isModuleIII && <ClickIntro />}
            {isModuleIII && appIII.config === "iii" && <QuestIII />}
            <Button
              sx={{ margin: "1rem 42.5%" }}
              variant="contained"
              color="primary"
              onClick={handleIntro}
            >
              Comenzar
            </Button>
          </Paper>
        ) : (
          ""
        )}
      </Box>
    );
  };

  const QuestIntro = () => (
    <>
      <h4
        className="my-4"
        style={{ color: "#fff", fontWeight: "500", textAlign: "center" }}
      >
        Bienvenido a la evaluación de Tiempo de reacción
      </h4>
      <br />
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        En la siguiente prueba se mide el tiempo en que usted se demora en percibir un
riesgo que se presentará a través de un video.  Usted debe observar la situación
de conducción, identificar cuando aparece un riesgo haciendo clic con el cursor
en cualquier parte de la pantalla dentro del video.
      </p>
      <span className="font-bold text-white">Instrucciones:</span>
      <ul
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
          listStyleType: "inherit",
          marginLeft: "1rem",
        }}
      >
        <li>
        Antes de comenzar la prueba, aparecerá un ejercicio de ensayo que le permitirá hacerse una idea de cómo son los siguientes casos. Este ejercicio no se contabilizará en el puntaje final de la prueba.
        </li>
        <li>
        Observará 10 videos y debe hacer clic con su mouse, rápidamente en la pantalla, cuando perciba un riesgo.
        </li>
        <li>
        Se recomienda hacer clic sólo cuando perciba el riesgo.
        </li>
        <li>
        En cada video, usted debe asumir la perspectiva desde el punto de vista del conductor.
        </li>
        <li>
        Hacer clic en la pantalla no representa la acción de frenar, representa el momento en que usted identifica el riesgo.
        </li>
        <li>
        No debe intentar reaccionar antes de tiempo porque no se contabilizará su respuesta, sólo debe hacer clic, cuando perciba el riesgo.
        </li>
        <li>
        Contará con 5 minutos para realizar toda la prueba.
        </li>
        <li>
        Al finalizar, debe hacer clic en terminar intento para enviar sus respuestas.
        </li>
      </ul>
      {/* <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        El siguiente ejercicio mide la capacidad de identificar y reaccionar
        ante una situación de riesgo en la conducción. Usted debe realizar 2
        ejercicios los cuales se dividen en:
      </p> */}
      {/* <div className="px-4">
        <span className="font-bold text-white">Ejercicio 1</span>
        <p
          style={{
            color: "#fff",
            fontWeight: "300",
            textAlign: "left",
            marginBottom: "1rem",
          }}
        >
          Usted tendrá 5 minutos para ver 5 videos en los cuales debe hacer clic
          rápidamente cuando crea que aparezca un riesgo. El objetivo del
          ejercicio, es evaluar su tiempo de reacción ante una situación de
          peligro en la conducción. Importante: Hacer clic no representa la
          acción de frenar, representa la identificación de un posible riesgo
          durante el video, desde su punto de vista.
        </p>
        <span className="font-bold text-white">Ejercicio 2</span>
        <p
          style={{
            color: "#fff",
            fontWeight: "300",
            textAlign: "left",
            marginBottom: "1rem",
          }}
        >
          Usted debe visualizar 5 videos que se reproducirán de manera
          automática en la cual debe identificar una posible situación de
          riesgo. La pantalla tendrá 4 divisiones y usted tendrá que elegir en
          cuál cuadrante se encuentra el riesgo, seleccionando el número del
          cuadrante en las alternativas que se le muestren. A continuación,
          usted deberá responder 2 preguntas relacionadas con la actitud del
          conductor y la consecuencia del riesgo. Usted tendrá 10 minutos para
          realizar este ejercicio, pasado este tiempo su evaluación será enviada
          automáticamente.
        </p>
      </div> */}
      <span className="font-bold text-white">Atención:</span>
      <ul
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
          listStyleType: "none",
        }}
      >
        <li>
          Recomendamos aumentar el brillo de su pantalla, para una mejor
          experiencia.
        </li>
        <li>
          Antes de comenzar, lea cuidadosamente las instrucciones de cada
          evaluación.
        </li>
        <li>
          Si abandona, o recarga la página, las preguntas se reordenarán y se
          perderá el progreso.
        </li>
      </ul>
      {/*  <h4
        className="my-4"
        style={{ color: "#fff", fontWeight: "500", textAlign: "center" }}
      >
        Bienvenido a la evaluación de predicción de riesgos en la conducción
      </h4>
      <br />
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        A continuación, se le presentarán 9 videos en los que usted tendrá que
        identificar una situación de riesgo, mientras se conduce. Usted tendrá
        15 minutos para realizar toda la evaluación, y deberá responder 3
        preguntas en cada video. Si sobrepasa ese tiempo, su respuesta se
        considerará errónea. Tiene que responder rápidamente como si estuviera
        en una situación real.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Deberá completar los 9 videos para dar por finalizada la evaluación. El
        tiempo se mostrará en un cronómetro en la parte superior derecha. En
        cada video se mostrará una situación de tránsito en la que usted figura
        como el conductor.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        1.- En la primera se dividirá la pantalla en cuatro y usted tendrá que
        responder en qué cuadrante se encuentra el riesgo. Tiene que responder
        el número del cuadrante en las opciones que se le mostrarán.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        2.- Se le indicarán varias acciones y usted tendrá que elegir la que
        usted tomaría en esa situación.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        3.- Se le van a indicar varias situaciones que pueden describir lo que
        sucederá luego de la detención del video y usted debe elegir lo que cree
        que pasará.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Recuerde que debe responder en forma rápida, igual como si estuviera en
        la situación real. Si supera el tiempo las respuestas se enviarán de
        forma automática y se contarán como erróneas. Contará con 2 ejercicios
        de ensayo. Luego de éstos, comenzará el ensayo y a contar el tiempo en
        el cronometro en la parte superior.
      </p> */}
    </>
  );
  const ClickIntro = () => (
    <>
      <h4
        className="my-4"
        style={{ color: "#fff", fontWeight: "500", textAlign: "center" }}
      >
        Bienvenido a la evaluación de tiempo de reacción en la conducción
      </h4>
      <br />
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        A continuación, se le presentarán 9 videos en los que usted tendrá que
        identificar una posible situación de riesgo, haciendo click en la
        pantalla. tendrá 3 segundos para identificar el riesgo en cada video.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Usted tendrá 7 minutos para realizar la evaluación. Si sobrepasa ese
        tiempo, su respuesta se considerará errónea.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Tiene que responder rápidamente como si estuviera en una situación real.
        Una vez transcurrido el tiempo, se desplegará el siguiente video y así
        sucesivamente hasta completar los 9. El tiempo se mostrará en un
        cronómetro en la parte superior derecha. En cada video se mostrará una
        situación de tránsito en la que usted figura como el conductor.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Recuerde que debe responder en forma rápida, igual como si estuviera en
        la situación real. Si supera el tiempo tendrá una respuesta errónea.
      </p>
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        Contará con 2 ejercicios de ensayo. Luego de éstos, comenzará la ensayo
        y a contar el tiempo en el cronometro en la parte superior.
      </p>
    </>
  );
  const QuestIII = () => (
    <>
      <h4
        className="my-4"
        style={{ color: "#fff", fontWeight: "500", textAlign: "center" }}
      >
        Bienvenido a la Prueba de tiempo de percepción y análisis del riesgo
      </h4>
      <br />
      <p
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
        }}
      >
        La siguiente prueba, mide su capacidad de percibir, identificar y
        evaluar un riesgo durante la conducción. Se presentarán videos donde se
        muestra una situación de conducción desde distintas perspectivas. Usted
        debe observar atentamente todo lo que sucede e identificar la situación
        de riesgo que se muestra. El video se detendrá y usted tendrá que
        responder preguntas sobre éste.
      </p>
      <span className="font-bold text-white">Instrucciones:</span>
      <ul
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
          listStyleType: "inherit",
          marginLeft: "1rem",
        }}
      >
        <li>
        Antes de comenzar la prueba, aparecerá un ejercicio de ensayo que le
        permitirá hacerse una idea de cómo son los siguientes casos. Este
        ejercicio no se contabilizará en el puntaje final de la prueba.
        </li>
        <li>
        Observará 5 videos, que se reproducirán de manera automática, en la cual
        debe identificar una posible situación de riesgo.
        </li>
        <li>
        La pantalla tendrá 4
        divisiones y usted tendrá que ubicar el cuadrante en el que se encuentra
        el riesgo.
        </li>
        <li>
        Debe seleccionar el número de cuadrante que se muestran en
        las alternativas.
        </li>
        <li>
        Debe responder 2 preguntas relacionadas con la
        actitud del conductor y la consecuencia del riesgo.
        </li>
        <li>
        Contará 10 minutos
        para realizar toda la prueba.
        </li>
        <li>
        Al finalizar el tiempo, las respuestas
        serán enviadas automáticamente.
        </li>
      </ul>
      <span className="font-bold text-white">Atención:</span>
      <ul
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
          listStyleType: "none",
        }}
      >
        <li>
          Recomendamos aumentar el brillo de su pantalla, para una mejor
          experiencia.
        </li>
        <li>
          Antes de comenzar, lea cuidadosamente las instrucciones de cada
          evaluación.
        </li>
        <li>
          Si abandona, o recarga la página, las preguntas se reordenarán y se
          perderá el progreso.
        </li>
      </ul>
    </>
  );

  return (
    <div className="card-body p-4 px-4">
      <div
        style={{
          background: modal_type === "intro" ? "transparent" : "#212529",
          width: isMobile ? "100%" : "60%",
          minHeight: "50vh",
        }}
        className="m-auto p-3"
      >
        {!loading && modal_type !== "intro" && (
          <div className="d-flex justify-content-center align-items-center mb-4 mt-3">
            <h4
              style={{
                margin: 0,
                fontWeight: "400",
                width: "auto",
                textJustify: "justify",
              }}
              className="card-title text-white mx-auto"
            >
              {modal_state.modal_title}
            </h4>
          </div>
        )}
        {modal_state.modal_title.includes("cuadrante") && (
          <div
            style={{
              marginBottom: 10,
              width: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              width={"70%"}
              alt="cuadrante"
              src={require(`../../frames/${isModuleIII ? appIII.v.video_id :app.v.video_id}.png`)}
            />
          </div>
        )}
        <div
          className="d-flex px-0"
          style={{ width: "71.8%", margin: "0 auto", verticalAlign: "center" }}
        >
          <RadioGroup
            aria-label="gender"
            defaultValue="female"
            name="radio-buttons-group"
          >
            {!loading && modal_type === "box"
              ? answers &&
              answers.map((answer, idx) => {
                return (
                  <FormControlLabel
                    sx={{ color: "#fff", margin: "0.1rem 0" }}
                    key={idx}
                    value={answer}
                    control={<Radio />}
                    label={answer}
                    onChange={handleChange}
                  />
                );
              })
              : ""}
          </RadioGroup>
        </div>

        {!loading && modal_type === "box" ? (
          <Box
            sx={{
              margin: "1rem 2rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ margin: "1rem" }}
              variant="contained"
              color="primary"
              onClick={handleResponse}
              disabled={!user_response ? true : false}
            >
              Confirmar
            </Button>
          </Box>
        ) : (
          ""
        )}
        {modal_type === "intro" && !loading && <ModalBody />}
        {loading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10% 0",
            }}
          >
            <Load />
          </div>
        )}
      </div>
    </div>
  );
};

export { Quest };
