import React from "react";
import ReturnToModuleList from "../ReturnToModuleList";
import { Button } from "@mui/material";

function FormularioEnviado({href =null, showHref=true}) {

  return (
    <>
      <div style={styles.completed}>
        <h1 style={styles.topCompleted}>Módulo Completado</h1>
        <div
          className="linkNavigate flex items-center"
          style={styles.navigateButton}
        >
          Para volver al campus virtual puedes cerrar esta ventana.
          {/* <a
                                        style={{ textDecoration: "underline" }}
                                        href={`http://elearning.inatrans.cl/course/view.php?id=${auth.auth.cookies.course_id}`}
                                    >
                                        <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                                            aquí
                                        </Button>
                                    </a> */}
        </div>
        {href != null && showHref && <p className="lead text-black mt-3">
          Para continuar al siguiente módulo:{" "}
          <a
            style={{ textDecoration: "underline" }}
            //href={`?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=15151515&url_name=inatrans-actitud-y-conducta&config=inatrans&intentos=test`}
            href={href}
          >
            <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
              aquí
            </Button>
          </a>
        </p>}
        {/* {<p className="lead  mt-3 text-black">
            Para regresar a la lista de actividades:{" "}
            <a
              style={{ textDecoration: "underline",marginLeft: "5px" }}
              href={`https://tipconsola.inatrans.cl/?config=modulelist&course_id=123123123&course_name=Evaluación%20Aversión%20al%20riesgo%20para%20conductores%2023&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl&url_name=modules`}
            >
              <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                aquí
              </Button>

            </a>
          </p>}  */}
        <ReturnToModuleList />
      </div>
    </>
  );
}

export default FormularioEnviado;

const styles = {
  wrapper: {
    padding: "70px 0px",
    width: "100vw",
    minHeight: "100vh",
    height: "100%",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    borderBottomWidth: 0.1,
    borderBottomColor: "rgb(202 208 215)",
    borderBottomStyle: "solid",
    padding: "0 0 12px 0",
  },
  headerText: {
    textTransform: "uppercase",
    color: "rgb(202 208 215)",
    fontWeight: 700,
    fontFamily: "Roboto,sans-serif",
    letterSpacing: ".02rem",
  },
  headerTextLight: {
    fontFamily: "Roboto,sans-serif",
    color: "rgb(202, 208, 215)",
    fontSize: ".75rem",
    fontWeight: 700,
    letterSpacing: ".06rem",
    lineHeight: "1rem",
    textTransform: "uppercase",
  },
  subtitle: {
    fontFamily: "Roboto,sans-serif",
    color: "rgb(202 208 215)",
    fontWeight: 700,
  },
  subtitleLight: {
    marginTop: 12,
    fontFamily: "Roboto,sans-serif",
    color: "rgb(202 208 215)",
    fontWeight: 400,
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
  },
  completed: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    marginTop: "20%",
    padding: "1.5rem",
  },
  topCompleted: {
    color: "white",
    backgroundColor: "rgb(52, 152, 219)",
    padding: "1rem",
    boxShadow: "5px 5px 12px 5px #9d9b9b",
    borderBottom: "1px solid #1979ac",
  },
  bottomCompleted: {
    margin: "1rem",
    flexDirection: "column",
    color: "black",
    fontWeight: "500",
  },
  next: {
    backgroundColor: "rgb(52, 152, 219)",
    display: "flex",
    color: "white",
    justifyContent: "center",
    padding: "0.5rem 1.5rem",
  },
  navigateButton: {
    display: "flex",
    color: "black",
    justifyContent: "center",
    padding: "0.5rem 1.5rem",
    cursor: "pointer",
  },
};
