import React from "react";
import logo_tipddy from "../../assets/logo-tipddy.png";
import { useAuth } from "../../provider/auth";
import { Button } from "@mui/material";
import ReturnToModuleList from "../../components/ReturnToModuleList";
import FormularioEnviado from "../../components/FormularioEnviado";

export const InatransPlayerCompleted = ({url_name = ''}) => {
  const { auth } = useAuth();

    React.useEffect(() => {
      console.log(auth)
    },[auth])
  return (
    <div
      className="d-flex h-100 text-center text-white bg-dark"
      style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0,
       }}
    >
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column flex justify-center items-center" 
        
      >
        <main /* style={{
           backgroundColor: "rgba(52, 152, 219, 0.8)"
        }} */ className="px-3 py-10 rounded-sm flex flex-col justify-center items-center  w-fit h-fit" >

          <FormularioEnviado showHref={url_name !== "inatrans-evaluacion-psicologica"} href={`https://tipconsola.inatrans.cl/?course_id=${auth.cookies.course_id}&course_name=${auth.cookies.course_name}&url_name=${url_name}&user_id=${auth.cookies.user_id}&user_name=${auth.cookies.user_name}&user_email=${auth.cookies.user_email}&url_cmid=${auth.cookies.url_cmid}&config=inatrans`}/>
          {/* <h1 className="text-2xl mb-3">Este modulo ya se encuentra completado</h1>
          <p className="lead mt-3 text-white">
            Para volver al campus virtual puedes cerrar esta ventana.{" "}
           
          </p>
          {url_name !== "inatrans-evaluacion-psicologica" && <p className="lead  mt-3">
            Para continuar al siguiente módulo{" "}
            <a
              style={{ textDecoration: "underline" }}
              href={`https://tipconsola.inatrans.cl/?course_id=${auth.cookies.course_id}&course_name=${auth.cookies.course_name}&url_name=${url_name}&user_id=${auth.cookies.user_id}&user_name=${auth.cookies.user_name}&user_email=${auth.cookies.user_email}&url_cmid=${auth.cookies.url_cmid}&config=inatrans`}
            >
              <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                aquí
              </Button>

            </a>
          </p>} */}
          {/* {(auth.cookies.user_email === "agu.quiroz%40tipddy.cl" || auth.cookies.user_email === "agu.quiroz@tipddy.cl") && <p className="lead w-25 mt-3">
            Para regresar a la lista de actividades:{" "}
            <a
              style={{ textDecoration: "underline",marginLeft: "5px" }}
              //href={`https://tipconsola.inatrans.cl/?config=modulelist&course_id=123123123&course_name=Evaluación%20Aversión%20al%20riesgo%20para%20conductores%2023&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl&url_name=modules`}
              href={`https://tipconsola.inatrans.cl/?config=modulelist&course_id=123123123&course_name=Evaluación%20Aversión%20al%20riesgo%20para%20conductores%2023&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl&url_name=modules`}
            >
              <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                aquí
              </Button>

            </a>
          </p>} */}
          {/* <ReturnToModuleList text='text-white'/> */}
        </main>

        {/* <footer
          className="mt-auto text-white-50 d-flex "
          style={{ justifyContent: "center" }}
        >
          <img src={logo_tipddy} alt="..." className="img-rounded" />
          <p>Copyright Powered by Tipddy</p>
        </footer> */}
      </div>
    </div>
  );
};
