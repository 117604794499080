export const INATRANS = {
  RISK_INTERCEPTOR: "inatrans-identificacion-de-riesgos",
  RISK_PREDICTION: "inatrans-prediccion-de-riesgos",
  PSYCHOLOGY_QUIZ: "inatrans-evaluacion-psicologica",
  ATTITUDE_BEHAVIOR: "inatrans-actitud-y-conducta",
  CERTIFICATE: "certificate",
  PDF: "pdf",
  TIEMPO_PERCEPCION_ANALISIS_RIESGO:'inatrans-tiempo-de-percepcion-y-analisis-de-riesgo'
};

export const REACT_APP_URL = {
  PROD: "https://tipconsola.tipddy.cl/",
  DEV: "https://tipconsoladev.tipddy.cl/",
  LOCAL: "http://localhost:3000/",
};

export const URL_NAMES = {
  MODULO_I: "Prediccion de riesgo",
  MODULO_II: "Identificacion de riesgo",
  MODULO_III: "Evaluacion psicologica",
  MODULO_IV: "Actitud y conducta",
  CERTIFICATE: "CERTIFICATE",
  PDF: "PDF",
};

export const COOKIES = {
  MODULO_I: // // videos: Tiempo de reacción y tiempo de percepción e identificación del riesgo
    "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=1313131113&url_name=quest&config=inatrans&intentos=test&brand=inatrans",
  MODULO_II: // imágenes:Identificación de riesgos
    "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=1414141413&url_name=inatrans-identificacion-de-riesgos&config=inatrans&intentos=test",
  MODULO_III:// evaluacion psicologica
    "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=15151515&url_name=inatrans-evaluacion-psicologica&config=inatrans&intentos=test",
  MODULO_IV: // actitud y conducta
    "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=15151515&url_name=inatrans-actitud-y-conducta&config=inatrans&intentos=test",
  CERTIFICATE:
    "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=16161616&url_name=certificate&config=inatrans",
  PDF: "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=171717&url_name=pdf&config=inatrans",
};


/*  
ORDEN OFICIAL DE LAS ACTIVIDADES
1. Identificacion de Riesgo / Imagenes
2. Tiempo de Reacción / Reaccionar Videos
3. Tiempo de Percepción y analisis de Riesgo / Videos con cuadrantes
4. Evaluacion de Actitud y conducta / Cuestionario 24 preguntas
5. Evaluacion Psicologica /  Cuestionario 83 preguntas
*/

export const INATRANS_V2 = {
  RISK_INTERCEPTOR: "inatrans-identificacion-de-riesgos",
  RISK_PREDICTION: "inatrans-prediccion-de-riesgos",
  PSYCHOLOGY_QUIZ: "inatrans-evaluacion-psicologica",
  ATTITUDE_BEHAVIOR: "inatrans-actitud-y-conducta",
  CERTIFICATE: "certificate",
  PDF: "pdf",
};

export const URL_NAMES_V2 = {
  MODULO_I: "Identificacion de Riesgo", // ina1
  MODULO_II: "Tiempo de Reacción", //ina0
  MODULO_III: "Tiempo de Percepción y analisis de Riesgo", //inaiii
  MODULO_IV: "Evaluacion de Actitud y conducta", //ina4
  MODULO_V: "Evaluacion Psicologica", //ina2
  CERTIFICATE: "CERTIFICATE",
  PDF: "PDF",
};


export const COOKIES_V2 = {
  MODULO_I: "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=1414141413&url_name=inatrans-identificacion-de-riesgos&config=inatrans&intentos=test",
  MODULO_II: "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=1313131113&url_name=inatrans-tiempo-de-reaccion&config=inatrans&intentos=test&brand=inatrans",
  MODULO_III: "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=1313131113&url_name=inatrans-tiempo-de-percepcion-y-analisis-de-riesgo&config=inatrans&intentos=test&brand=inatrans",
  MODULO_IV: "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=15151515&url_name=inatrans-actitud-y-conducta&config=inatrans&intentos=test",
  MODULO_V :"?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=15151515&url_name=inatrans-evaluacion-psicologica&config=inatrans&intentos=test",
  CERTIFICATE: "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=16161616&url_name=certificate&config=inatrans",
  PDF: "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=171717&url_name=pdf&config=inatrans",
}


// MODULO_I : ?course_id=935&course_name=beta&user_id=1111&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=11113&url_name=quest&config=inatrans&intentos=2
// MODULO_II : ?course_id=935&course_name=local&user_id=2211&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=26005&url_name=inatrans-identificacion-de-riesgos&config=inatrans&intentos=2
// MODULO_III : ?course_id=935&course_name=beta&user_id=1111&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=11115&url_name=inatrans-evaluacion-psicologica&config=inatrans&intentos=2
// CERTIFICATE : ?course_id=935&course_name=beta&user_id=1111&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=11116&url_name=certificate&config=inatrans
// PDF : ?course_id=12&course_name=beta&user_id=1111&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=11116&url_name=pdf&config=inatrans


//ADMIN
// 
// ?config=admin&course_id=935&course_name=Evaluación%20Aversión%20al%20riesgo%20para%20conductores%2023&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl
// ?config=admin&course_id=123123123&course_name=Evaluación%20Aversión%20al%20riesgo%20para%20conductores%2023&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl
// MODULELIST
// ?config=modulelist&course_id=123123123&course_name=Evaluación%20Aversión%20al%20riesgo%20para%20conductores%2023&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl&url_name=modules

// MODULELIST CURSO 974
// https://tipconsola.inatrans.cl/?config=modulelist&course_id=974&course_name=Evaluacion%20Aversi%C3%B3n%20al%20riesgo%20para%20la%20conduccion%202025&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl&url_name=modules