import * as React from "react";

import { InatransLogo } from "../../components/navigation";
import { useAuth } from "../../provider/auth";


/* let MODULES = [
   {
    title: "Tiempo de reacción",
    href: "/?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=1313131113&url_name=quest&config=inatrans&intentos=test&brand=inatrans"
  },
   {
    title: "Identificación de riesgos",
    href: "/?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=1414141413&url_name=inatrans-identificacion-de-riesgos&config=inatrans&intentos=test"
  },
  {
    title: "Evaluación psicológica",
    href: "/?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=15151515&url_name=inatrans-evaluacion-psicologica&config=inatrans&intentos=test"
  },
   {
    title: "Actitud y conducta",
    href: "/?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=15151515&url_name=inatrans-actitud-y-conducta&config=inatrans&intentos=test"
  }
]; */

// course_id
// course_name
// user_id
// user_name
// user_email
// url_cmid
let MODULES = [
  {
    title: "Identificación de riesgo",
    staticParams: "url_name=inatrans-identificacion-de-riesgos&config=inatrans&intentos=test",
  },
  {
    title: "Tiempo de reacción",
    staticParams: "url_name=inatrans-tiempo-de-reaccion&config=inatrans&intentos=test&brand=inatrans",
  },
  {
    title: "Tiempos de percepción e identificación del riesgo",
    staticParams: "url_name=inatrans-tiempo-de-percepcion-y-analisis-de-riesgo&config=inatrans&intentos=test&brand=inatrans",
  },
  {
    title: "Evaluacion de Actitud y conducta",
    staticParams: "url_name=inatrans-actitud-y-conducta&config=inatrans&intentos=test",
  },
  {
    title: "Evaluación psicológica",
    staticParams: "url_name=inatrans-evaluacion-psicologica&config=inatrans&intentos=test",
  },
];

function ModuleList() {
  const {auth} = useAuth()
  const { course_id, course_name, user_id, user_name, user_email, url_cmid } = auth.cookies; 

  React.useEffect(() => {
    console.log("auth: ", auth.cookies)
    console.log("ModuleList")
  }
  , [auth]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: "0",
          right: "0",
          height: "75px",
          backgroundColor: "rgb(52, 152, 219)",
          boxShadow: "rgb(0 0 0 / 28%) 0px 4px 8px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          zIndex: "10",
          padding: "0 2rem",
        }}
      >
        <InatransLogo />
      </div>
      
      <div className="bg-sky-500/70 p-4 flex flex-col">
        <h1 className="text-gray-200 mb-6 text-3xl">Lista de actividades</h1>
        <ul className="list-inside">
          {
            MODULES.map((module, index) => (
              <li className="py-2" key={index}>
                <a 
                  href={`http://tipconsola.inatrans.cl/?course_id=${course_id}&course_name=${course_name}&user_id=${user_id}&user_name=${user_name}&user_email=${user_email}&url_cmid=${url_cmid}&${module.staticParams}`} 
                  className="text-neutral-200 hover:text-neutral-50"
                >
                  {module.title}
                </a>
              </li>
            ))
          }
        </ul>

        {(auth.cookies.user_id === "37927" || auth.cookies.user_id === "23059") && <a href={`http://tipconsola.inatrans.cl/?config=admin&course_id=${auth.cookies.course_id}&course_name=${auth.cookies.course_name}&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl`} className="text-neutral-200 hover:text-neutral-50 text-xl mt-10 self-center">Reporte de curso</a>}
      </div>
      
       
    </>
  );
}

export { ModuleList };
