import { Button, Avatar, Badge } from "@mui/material";
/* import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import CameraIndoorIcon from '@mui/icons-material/CameraIndoor'; */
import { styled } from "@mui/material/styles";
import { LinksView } from "../links-view";
/* import { Link } from 'react-router-dom' */
import { useApp } from "../../provider/app";
import logo from "./log.png";
import { create } from "qrcode";
import { useEffect } from "react";
import { useAppIII } from "../../provider/app/AppIIIProvider";
import { useConfig } from "../../provider/config";

const WelcomeBadge = ({ auth, isMobile }) => {
  /*   const welcome = `${auth.user.user_name}` */

  const nav_badge = {
    display: "flex",
    padding: "0",
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#293e4f",
      color: "#293e4f",
      //boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(0.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.5)",
        opacity: 0.2,
      },
    },
  }));

  return (
    <nav className="badge" style={nav_badge}>
      {/*       {!isMobile && <h3 style={{ display: 'flex', alignItems: 'center', fontSize: 18, margin: '0 1rem'}}>{welcome}</h3> } */}
      <StyledBadge
        overlap="circular"
        sx={{ cursor: "pointer" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
      >
        <Avatar
          sx={{ color: "rgb(52, 152, 219)", backgroundColor: "#fff" }}
          alt="Avatar"
        >
          {auth.user.user_name[0].toUpperCase()}
        </Avatar>
      </StyledBadge>
    </nav>
  );
};

export const NavBar = ({ auth, isMobile }) => {
  const { cron } = useApp();
  const { cron: cronIII } = useAppIII()
  
  
  const nav_css = {
    position: "fixed",
    left: "0",
    right: "0",
    height: "3.5rem",
    backgroundColor:
      auth.cookies.config === "inatrans" ? "rgb(52, 152, 219)" : "#343a40",
    boxShadow: "rgb(0 0 0 / 28%) 0px 4px 8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    zIndex: "10",
    padding: "0 1rem",
  };

  return (
    <div style={nav_css}>
      {auth.cookies.config === "inatrans" ? <InatransLogo /> : null}
      <div
        style={{
          padding: "0 2rem",
          margin: "0",
          marginLeft: "auto",
        }}
      >
        {auth.user ? (
          <WelcomeBadge auth={auth} isMobile={isMobile} />
        ) : (
          <LoginButton />
        )}
      </div>

      {(auth.cookies.url_name === "quest"|| auth.cookies.url_name ==="inatrans-tiempo-de-reaccion" || auth.cookies.url_name === "inatrans-tiempo-de-percepcion-y-analisis-de-riesgo" ) && (
        <LinksView auth={auth} cron={auth.cookies.url_name === "inatrans-tiempo-de-percepcion-y-analisis-de-riesgo" ? cronIII : cron} />
      )}
    </div>
  );
};

export const InatransLogo = ({ size = "md" }) => {
  let sizes = {
    md: 150,
    sm: 120,
  };

  return (
    <>
      <img alt="..." src={logo} width={sizes[size]} />
    </>
  );
};

export function Navigation({ isMobile, auth }) {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {/*       <SideBar auth={auth} isMobile={isMobile}/>
       */}{" "}
      <NavBar auth={auth} isMobile={isMobile} />
    </div>
  );
}

const LoginButton = () => (
  <Button variant="contained" color="primary">
    Iniciar sesion
  </Button>
);

/* const SideBar = ({ auth, isMobile }) => {
  console.log(auth);
  const anchor_css = {
    color: 'white',
    fontSize: '1rem',
    textDecoration: 'none'
  }
  
  const paper_css = {
    display: isMobile ? 'none' : 'block',
    height: '100vh',
    width: '5rem',
    position: 'fixed',
    zIndex: '-10',
    backgroundColor: '#212529',
    paddingTop: '4rem'
  }

  return(
    <Paper 
      style={paper_css}
    >
    <IconButton aria-label="">
      <Link to="/" style={anchor_css}>
      <CameraIndoorIcon/>
        Home
      </Link>
    </IconButton>  
    { auth.config && auth.config === 'moodle' ? (
      <IconButton aria-label="">
      <Link to="/protected" style={anchor_css}>
        <VideoLibraryIcon/>
        Console
      </Link>
    </IconButton>
      ):(
        <IconButton aria-label="">
        <Link to="/player" style={anchor_css}>
          <VideoLibraryIcon/>
          Video
        </Link>
      </IconButton>
      )}
    </Paper>
  )
} */
